export default {
  "Информация о резидентах": "Information about residents",
  "2022 г.": "2022 y.",
  "Весь год": "All year",
  "1-квартал": "1-quarter",
  "2-квартал": "2-quarter",
  "3-квартал": "3-quarter",
  "4-квартал": "4-quarter",
  quarter_1: "1-quarter",
  quarter_2: "2-quarter",
  quarter_3: "3-quarter",
  quarter_4: "4-quarter",
  "Все регионы": "All regions",
  "Республика Каракалпакстан": "Karakalpakstan Republic",
  "Бухарская область": "Bukhara Region",
  "Самаркандская область": "Samarkand Region",
  "Навоийская область": "Navoiy Region",
  "Андижанская область": "Andijan Region",
  "Ферганская область": "Fergana Region",
  "Сурхандарьинская область": "Surkhandarya Region",
  "Сырдарьинская область": "Syrdarya Region",
  "Хорезмская область": "Khorezm Region",
  "Ташкентская область": "Tashkent Region",
  "Кашкадарьинская область": "Kashkadarya Region",
  "Джизакская область": "Jizzakh Region",
  "Наманганская область": "Namangan Region",
  "город Ташкент": "Tashkent City",
  "Всего резидентов:": "Total residents:",
  "Экспортеры:": "Exporters:",
  "Резиденты с участием иностранного капитала:":
    "Residents with participation of foreign capital:",
  "По форме собственности:": "By form of ownership:",
  "Объём оказанных услуг:": "Scope of rendered services:",
  "Объём экспорта:": "Export volume:",
  "Прогноз:": "Forecast:",
  "тыс. сум": "thousand soums",
  "Предоставлена:": "Provided:",
  "Услуга “Virtual office”": '"Virtual office" service',
  "Услуга “One Stop Shop”": "“One Stop Shop” service",
  "Отказ в предоставлении статуса резидента": "Refusal to grant status",
  "Получившие статус резидента": "Receiving resident status",
  "Лишено статуса резидента": "Revoked resident status",
  "Образовательные компании:": "Educational companies",
  "Количество сотрудников:": "Employees count",
  раза: "",
  Регионы: "Regions",
  "Прочие 7 стран по": "Other 7 countries",
  "г.": "y.",
  "Республика Каракалпакстан": "Karakalpakstan Republic",
  "Бухарская область": "Bukhara Region",
  "Самаркандская область": "Samarkand Region",
  "Навоийская область": "Navoiy Region",
  "Андижанская область": "Andijan Region",
  "Ферганская область": "Fergana Region",
  "Сурхандарьинская область": "Surkhandarya Region",
  "Сырдарьинская область": "Syrdarya Region",
  "Хорезмская область": "Khorezm Region",
  "Ташкентская область": "Tashkent Region",
  "Кашкадарьинская область": "Kashkadarya Region",
  "Джизакская область": "Jizzakh Region",
  "Наманганская область": "Namangan Region",
  "город Ташкент": "Tashkent City",
  "Список стран экспортеров услуг резидентов IT Park":
    "List of countries exporting services of IT Park residents",
  Россия: "Russia",
  Беларусь: "Belarus",
  Казахстан: "Kazakhstan",
  США: "USA",
  "Южная Корея": "South Korea",
  Великобритания: "Great Britain",
  Германия: "Germany",
  Нидерланды: "Netherlands",
  ОАЭ: "UAE",
  Швеция: "Sweden",
  Литва: "Lithuania",
  Китай: "China",
  Эстония: "Estonia",
  Латвия: "Latvia",
  Израиль: "Israel",
  Кипр: "Cyprus",
  "Новая Зеландия": "New Zealand",
  Ирландия: "Ireland",
  Франция: "France",
  Гонконг: "Hong Kong",
  Канада: "Canada",
  Австрия: "Austria",
  Италия: "Italy",
  Бельгия: "Belgium",
  Сингапур: "Singapore",
  Швейцария: "Switzerland",
  Венгрия: "Hungary",
  Камбоджа: "Cambodia",
  Грузия: "Georgia",
  Румыния: "Romania",
  Индия: "India",
  Азербайджан: "Azerbaijan",
  Финляндия: "Finland",
  Малайзия: "Malaysia",
  Таджикистан: "Tajikistan",
  Чехия: "Czech",
  Турция: "Turkey",
  Беларусь: "Belarus",
  "Другие страны": "Other countries",
  OOO: "LLC",
  ИП: "FE",
  ЧП: "PE",
  ГУП: "SUE",
  СП: "JV",
  ННО: "NNO",
  НОУ: "NEI",
  АО: "JSC",
  ДП: "SE",
  УП: "UE",
  "долл. США": "USD",
  "Показать все": "More",
  "за year год": "for {year}",
  developers_count: "Dasturchilar",
  foreign_developers_count: "Chet elliklar",
  teachers_count: "O'qituvchilar",
  foreign_teachers_count: "Xalqaro sertifikatga ega o'qituvchilar",
  "Данные за quarter квартал year года":
    "Данные за {quarter} квартал {year} года",
  "Данные за year года": "Данные за {year} года",
  "count страны": "{count} ta davlat",
  gu: "SU",
  up: "UE",
  dp: "SE",
  nno: "NNO",
  sp_ooo: "JV LLC",
  chp: "PE",
  ao: "JSC",
  nou: "NEI",
  sp: "JV",
  gup: "SUE",
  ooo: "LLC",
  ip_ooo: "FE LLC",

  "Показать всех резидентов": "Показать всех резидентов",
  "Топ 20 компаний": "Топ 20 компаний",
  "Форма собственности": "Форма собственности",
  "Наименование компании": "Наименование компании",
  Регион: "Регион",
  "Страна учредитель": "Страна учредитель",
  "Дата регистрации": "Дата регистрации",
  "Направление деятельности": "Направление деятельности",
  "Количество сотрудников": "Количество сотрудников",
  Всего: "Всего",
  Программисты: "Программисты",
  Иностранцы: "Иностранцы",
  Учителя: "Учителя",
  "Учителя с межд. сертификатом": "Учителя с межд. сертификатом",
  Направление: "Направление",
  "Все направление": "Все направление",
  Год: "Год",
  Период: "Период",
  "Всего резидентов": "Всего резидентов",
  "Объем оказанных услуг": "Объем оказанных услуг",
  млрд: "млрд",
  сум: "сум",
  "Объем экспорта": "Объем экспорта",
  млн: "млн",
  USD: "USD",
  "По направлению деятельности": "По направлению деятельности",
  "По форме собственности": "По форме собственности",
  "Тип организаций": "Тип организаций",
  "за весь период": "за весь период",
  "Показать все страны-экспортеры": "Показать все страны-экспортеры",
  "Резиденты с участием иностранного капитала":
    "Резиденты с участием иностранного капитала",
  Выход: "Выход",
  "Тип компании": "Тип компании",
  "Процентное соотношение": "Процентное соотношение",
  Экспортеры: "Экспортеры",
  Все: "Все",
  "count компаний": "{count} компаний",
  "Резиденты в разрезе регионов": "Residents by region",
  "Финансовые показатели": "Financial indicators",
  "Показать меньше": "Show less",
  "Города/районы": "Cities/districts",
};
