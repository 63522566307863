import { 
  categories,
  getFormOptions, 
  positions,
  regions,
  getCities
} from '@/api/classifiers';

export const actions = {
  getFormOptions({ commit }) {
    return new Promise((resolve, reject) => {
      getFormOptions()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_FORM_OPTIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPositions({ commit }, params) {
    return new Promise((resolve, reject) => {
      positions(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_POSITIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getRegions({ commit }, params) {
    return new Promise((resolve, reject) => {
      regions(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_REGIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCategories({ commit }, params) {
    return new Promise((resolve, reject) => {
      categories(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_CATEGORIES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCities({ commit }, params) {
    return new Promise((resolve, reject) => {
      getCities(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_CITIES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
